import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator } from 'summit-react';

function StacksEmpty({ t }) {
  return (
    <Card>
      <Card.Header className="stack empty">
        <p>{t('stacks.labels.empty')}</p>
      </Card.Header>
    </Card>
  );
}

export default withTranslator()(StacksEmpty);
