import { throwForHttpStatus } from './errors';

/**
 * An abstract base for client adapter implementations.
 */
class AbstractClientAdapter {
  constructor() {
    if (this.constructor === AbstractClientAdapter) {
      throw new Error('Cannot instantiate this class');
    }
  }

  /**
   * Performs a GET request.
   *
   * @param path
   * @return {Promise} that resolves to an object or array representing the
   *    API response entity or rejects with an HttpError subclass as
   *    appropriate
   */
  get(path) {
    throw new Error('must override _get method');
  }

  /**
   * Performs a POST request.
   *
   * @param path
   * @return {Promise} that resolves to an object or array representing the
   *    API response entity or rejects with an HttpError subclass as
   *    appropriate
   */
  post(path) {
    throw new Error('must override _post method');
  }
}

/**
 * A client adapter for the Axios HTTP client.
 */
class AxiosAdapter extends AbstractClientAdapter {
  constructor(delegate) {
    super();
    this._delegate = delegate;
  }

  get(path) {
    return this._delegate.get(path).then((response) => {
      throwForHttpStatus(response.statusCode, response.data);
      return Promise.resolve(response.data);
    });
  }

  post(path) {
    return this._delegate.post(path).then((response) => {
      throwForHttpStatus(response.statusCode, response.data);
      return Promise.resolve(response.data);
    });
  }
}

/**
 * A client adapter for the `fetch` client.
 */
class FetchAdapter extends AbstractClientAdapter {
  constructor(delegate) {
    super();
    this._delegate = delegate;
  }

  get(path) {
    return this._delegate.get(path).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json((data) =>
          throwForHttpStatus(response.statusCode, data)
        );
      }
    });
  }

  post(path) {
    return this._delegate.post(path).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json((data) =>
          throwForHttpStatus(response.statusCode, data)
        );
      }
    });
  }
}

const ClientAdapter = { Axios: AxiosAdapter, Fetch: FetchAdapter };
export default ClientAdapter;
