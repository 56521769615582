import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import Stack from './Stack';

function StackCards({ t, stacks, onSuspend, onResume }) {
  const details = stacks.map((stack) => (
    <Stack
      key={stack.stackId}
      stack={stack}
      onSuspend={onSuspend}
      onResume={onResume}
    />
  ));

  return (
    <>
      <Card className="heading">
        <Card.Header>
          <div className="row">
            <div className="col">{t('stacks.labels.stackId')}</div>
            <div className="col-6">{t('stacks.labels.links')}</div>
            <div className="col">{t('stacks.labels.lastModified')}</div>
            <div className="col">{t('stacks.labels.stackStatus')}</div>
          </div>
        </Card.Header>
      </Card>
      {details}
    </>
  );
}

export default withTranslator()(StackCards);
