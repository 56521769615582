// Error hierarchy for HTTP status codes

/**
 * Base class for all HTTP status errors.
 */
export class HttpError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.name = 'HttpError';
    this.statusCode = statusCode;
  }
}

/**
 * Base class for HTTP client errors (status codes 400-499)
 */
export class ClientError extends HttpError {
  constructor(message, statusCode) {
    super(message, statusCode);
    this.name = 'ClientError';
  }
}

/**
 * Base class for HTTP server errors (status codes 500-599)
 */
export class ServerError extends HttpError {
  constructor(message, statusCode) {
    super(message, statusCode);
    this.name = 'ServerError';
  }
}

/**
 * Unauthorized error (status code 403)
 */
export class UnauthorizedError extends ClientError {
  constructor(message, statusCode) {
    super(message, statusCode);
    this.name = 'UnauthorizedError';
  }
}

/**
 * Not Found error (status code 404)
 */
export class NotFoundError extends ClientError {
  constructor(message, statusCode) {
    super(message, statusCode);
    this.name = 'NotFoundError';
  }
}

/**
 * A function that throws an appropriate HttpError if the given status code
 * represents an error.
 *
 * @param {number} statusCode subject status code
 * @param {Object} responseBody response body associated with the status code
 */
export function throwForHttpStatus(statusCode, responseBody) {
  switch (statusCode) {
    case 403:
      throw new UnauthorizedError(errorMessage(responseBody), statusCode);
    case 404:
      throw new NotFoundError(errorMessage(responseBody), statusCode);
    default:
      if (statusCode >= 400 && statusCode < 500) {
        throw new ClientError(errorMessage(responseBody), statusCode);
      } else if (statusCode >= 500 && statusCode < 600) {
        throw new ServerError(errorMessage(responseBody), statusCode);
      } else if (statusCode >= 600) {
        throw new HttpError('unrecognized status code', statusCode);
      }
      break;
  }
}

/**
 * Gets an error message to be included in an HttpError.
 * @param {Object} responseBody
 * @returns {string} if `responseBody` contains an error message, else undefined
 */
function errorMessage(responseBody) {
  return responseBody && responseBody.message;
}
