import React from 'react';
import { useTable, useExpanded } from 'react-table';

export default function ExpansibleTable({
  columns,
  data,
  expandClassName,
  renderExpandComponent,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
    },
    useExpanded
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...restGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...restGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key, ...restHeaderProps } = column.getHeaderProps();
                return (
                  <th
                    key={key}
                    className={column.className}
                    {...restHeaderProps}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, restRowProps } = row.getRowProps();
          return (
            <>
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, restCellProps } = cell.getCellProps();
                  return (
                    <td
                      key={key}
                      className={cell.column.className}
                      {...restCellProps}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {renderExpandComponent && row.isExpanded && (
                <tr
                  className={expandClassName}
                  key={key + '-expanded'}
                  {...restRowProps}
                >
                  <td colSpan={visibleColumns.length}>
                    {renderExpandComponent({ row })}
                  </td>
                </tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
}
