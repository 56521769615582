/**
 * An HTTP client whose API matches that provided in the issuer-js-client
 * module, but which uses a self-issued JWT for bearer token authentication.
 */
export class SelfIssuedTokenClient {
  constructor(baseUrl, token) {
    this._baseUrl = baseUrl;
    this._token = this._fetch_token();
  }

  _fetch_token() {
    try {
      const token = require('../token.js').TOKEN;
      console.log('fetched token');
      return token;
    } catch (err) {
      console.log(
        'no token available; see README.md for details on how to use a self-issued token'
      );
      return null;
    }
  }

  getAccessToken() {
    return this._token;
  }

  _makeRequest(url, config) {
    if (!url) {
      throw new Error('url is required');
    }
    if (!url.match(/^[a-z]+:\/\//)) {
      url = `${this._baseUrl}${url}`;
    }

    config = config ? { ...config } : {};
    const headers = config.headers ? config.headers : {};
    delete config.headers;
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${this.getAccessToken()}`,
        ...headers,
      },
      ...config,
    });
  }

  get(url, config) {
    return this._makeRequest(url, {
      method: 'GET',
      ...config,
    });
  }

  post(url, data, config) {
    return this._makeRequest(url, {
      method: 'POST',
      data: data,
      ...config,
    });
  }

  put(url, data, config) {
    return this._makeRequest(url, {
      method: 'POST',
      data: data,
      ...config,
    });
  }

  del(url, config) {
    return this._makeRequest(url, {
      method: 'DELETE',
      ...config,
    });
  }

  request({ url, config }) {
    return this._makeRequest(url, config);
  }
}
