import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import StacksLink from './StacksLink';
import StackStatus from '../Stacks/StackStatus';

function AwaitingStable({ t, stack }) {
  return (
    <>
      <Card.Body>
        <p>{t('launcher.awaitStable')}</p>
        <StackStatus stack={stack} />
      </Card.Body>
      <Card.Footer>
        <StacksLink />
      </Card.Footer>
    </>
  );
}

export default withTranslator()(AwaitingStable);
