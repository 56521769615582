import React from 'react';
import { Button } from 'react-bootstrap';
import ConfirmAction from './ConfirmAction';
import { SERVICE, CONTAINER } from './DeployedServices';

function DeployedServiceActions({
  stackId,
  row,
  onShowLog,
  onSuspend,
  onResume,
}) {
  const [confirm, setConfirm] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [actionKey, setActionKey] = React.useState(null);

  function handleConfirmOutcome(confirmed) {
    if (confirmed) {
      action();
    }
    setConfirm(false);
    setAction(null);
    onResume && onResume();
  }

  function confirmAction(actionKey, action) {
    onSuspend && onSuspend();
    setAction(() => action);
    setActionKey(actionKey);
    setConfirm(true);
  }

  switch (row.original.type) {
    case SERVICE: {
      const service = row.original.model;
      return (
        <>
          {service && service.canRestart() && (
            <Button
              variant="light"
              onClick={() =>
                confirmAction('deployment.actions.restart', () =>
                  service.restart()
                )
              }
            >
              <i className="fas fa-redo-alt" />
            </Button>
          )}
          <ConfirmAction
            show={confirm}
            actionKey={actionKey}
            onOutcome={handleConfirmOutcome}
            translateContext={{ serviceName: row.values.name, stackId }}
          />
        </>
      );
    }
    case CONTAINER: {
      const container = row.original.model;
      return (
        <>
          {container && container.canFetchLog() && (
            <Button
              onClick={() => onShowLog && onShowLog(container)}
              variant="light"
            >
              <i className="far fa-file-alt" />
            </Button>
          )}
        </>
      );
    }
  }
}

export default DeployedServiceActions;
