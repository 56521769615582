import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import HealthStatus from '../../api/HealthStatus';
import RunStatus from '../../api/RunStatus';
import Spinner from '../util/Spinner';
import ApplicationLinks from '../Stacks/ApplicationLinks';
import StacksLink from './StacksLink';
import RunStatusIcon from '../Stacks/RunStatusIcon';
import HealthStatusIcon from '../Stacks/HealthStatusIcon';

const REFRESH_DELAY = 2500;

function LaunchComplete({ t, stack }) {
  const [deployment, setDeployment] = React.useState(null);

  const timer = React.useRef(null);

  function refreshDeployment() {
    stack &&
      stack.isDeployed() &&
      stack.fetchDeployment().then((deployment) => {
        setDeployment(deployment);
        timer.current = setTimeout(refreshDeployment, REFRESH_DELAY);
      });
  }

  React.useEffect(() => {
    refreshDeployment();
    return () => timer.current && clearTimeout(timer.current);
  }, [stack]);

  function spinStatus(runStatus) {
    return runStatus !== RunStatus.RUNNING && runStatus !== RunStatus.PENDING;
  }

  const runStatus = deployment && deployment.runStatus();
  return (
    <>
      <Card.Body>
        <p>{t('launcher.launched.prompt')}</p>
        <p>{t('launcher.launched.detail')}</p>
        <div className="row">
          <div className="col-2">
            {deployment ? (
              <>
                <RunStatusIcon status={runStatus} />
                {runStatus}
              </>
            ) : (
              <Spinner />
            )}
          </div>
          <div className="col-2">
            {deployment && (
              <>
                <HealthStatusIcon status={deployment.status.health} />
                {deployment.status.health}
              </>
            )}
          </div>
          <div className="spinner col">
            {deployment &&
              (spinStatus(runStatus) ||
                deployment.status.health !== HealthStatus.HEALTHY) && (
                <Spinner />
              )}
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="row">
          <div className="app-links col-10">
            <ApplicationLinks stack={stack} />
          </div>
          <div className="return-link col-2">
            <StacksLink />
          </div>
        </div>
      </Card.Footer>
    </>
  );
}

export default withTranslator()(LaunchComplete);
