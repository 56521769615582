import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslator } from 'summit-react';
import { STACKS_PATH } from '../MainRouter/MainRouter';

function StacksLink({ t }) {
  return <Link to={STACKS_PATH}>{t('launcher.stacks')}</Link>;
}

export default withTranslator()(StacksLink);
