import React from 'react';
import { withTranslator } from 'summit-react';
import LogModal from '../util/LogModal';
import LocaleDateTime from '../util/LocaleDateTime';
import Table from '../util/Table';
import TaskStatusIcon from './TaskStatusIcon';
import ConfirmAction from './ConfirmAction';
import TaskActions from './TaskActions';

import './Tasks.scss';

function Tasks({ t, tasks, onSuspend, onResume }) {
  const [action, setAction] = React.useState(null);
  const [actionKey, setActionKey] = React.useState(null);
  const [showLog, setShowLog] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState(false);

  function handleShowLog(task) {
    setSelectedTask(task);
    setShowLog(true);
  }

  function handleConfirmOutcome(confirmed) {
    if (confirmed) {
      action();
    }
    setAction(null);
    setActionKey(null);
    onResume && onResume();
  }

  function confirmAction(task, name, action) {
    onSuspend && onSuspend();
    setSelectedTask(task);
    setAction(() => action);
    setActionKey(`tasks.actions.${name}`);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t('tasks.labels.task'),
        accessor: 'taskId',
        className: 'double',
        Cell: ({ row }) => (
          <div>
            <TaskStatusIcon taskStatus={row.original.taskStatus} />
            {row.values.taskId}
          </div>
        ),
      },
      {
        Header: t('tasks.labels.statusTimestamp'),
        accessor: 'statusTimestamp',
        className: 'double',
        Cell: ({ row }) => (
          <LocaleDateTime isoInstant={row.values.statusTimestamp} />
        ),
      },
      {
        Header: t('tasks.labels.triggeredBy'),
        columns: [
          {
            Header: t('tasks.labels.pipelineId'),
            className: 'single',
            accessor: 'pipelineId',
          },
          {
            Header: t('tasks.labels.jobId'),
            className: 'single',
            accessor: 'jobId',
          },
        ],
      },
      {
        Header: t('tasks.labels.message'),
        accessor: 'message',
      },
      {
        Header: t('tasks.labels.actions'),
        className: 'single',
        Cell: ({ row }) => (
          <TaskActions
            t={t}
            task={row.original}
            onShowLog={handleShowLog}
            onAction={confirmAction}
          />
        ),
      },
    ],
    []
  );

  return (
    <div className="tasks">
      <Table columns={columns} data={tasks} />
      {showLog && (
        <LogModal
          title={t('tasks.logTitle')}
          loggable={selectedTask}
          show={showLog}
          onHide={() => setShowLog(false)}
        />
      )}
      {action !== null && (
        <ConfirmAction
          actionKey={actionKey}
          onOutcome={handleConfirmOutcome}
          translateContext={{ taskId: selectedTask.taskId }}
        />
      )}
    </div>
  );
}

export default withTranslator()(Tasks);
