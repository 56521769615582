import * as Names from './Names';
import LogModel from './LogModel';

/**
 * An object that represents a container in a service unit of the deployment
 * for a stack.
 */
export default class StackContainerModel {
  // These fields are defined by the REST API.
  // Declaring them here makes your IDE more helpful.
  name; // name of the container
  image; // docker image URI
  status; // status of the container
  links; // links that can be used to query the container

  /**
   * Constructs a new instance.
   * @param {AbstractClientAdapter} client the client adapter that was used to
   *    fetch `containerData`
   * @param {Object} containerData the API representation of a container in
   *    a stack deployment
   */
  constructor(client, containerData) {
    this._client = client;
    Object.assign(this, containerData);
  }

  canFetchLog() {
    return typeof this.links[Names.LOG] !== 'undefined';
  }

  fetchLog() {
    if (!this.canFetchLog()) {
      return Promise.resolve(new LogModel(this._client, {}));
    }
    return this._client
      .get(this.links[Names.LOG])
      .then((data) => new LogModel(this._client, data))
      .catch(() => new LogModel(this._client, { messages: [] }));
  }
}
