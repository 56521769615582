import React from 'react';
import RunStatus from '../../api/RunStatus';

import './StatusIcon.scss';

export default function RunStatusIcon({ status }) {
  return (
    <span className="status-icon">
      <i className={iconClasses(status)} />
    </span>
  );
}

function iconClasses(status) {
  switch (status) {
    case RunStatus.STOPPED:
      return 'red fas fa-ban';
    case RunStatus.EXITED:
    case RunStatus.RUNNING:
      return 'green fas fa-check-circle';
    default:
      return 'fas fa-spin fa-circle-notch';
  }
}
