import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'messages',
    fallbackNS: 'messages',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
    lng: 'en',

    // This method requires "saveMissing" to be set to true to get fired
    missingKeyHandler: (
      lngs,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options
    ) => {
      console.error(`Translation failed for key: '${key}';`, 'details:', {
        lngs,
        ns,
        key,
        fallbackValue,
        updateMissing,
        options,
      });
      throw new Error(
        `Translation failed for key: '${key}'; ns: ${ns}; see above for more details`
      );
    },

    missingInterpolationHandler: (text, value) => {
      console.error(`Interpolation failed for text: '${text}';`, 'details:', {
        text,
        value,
      });
      throw new Error(
        `Interpolation failed for text: '${text}'; see above for more details`
      );
    },
    ns: ['messages'], // namespaces listed here will be fetched eagerly
    saveMissing: true,
  });
