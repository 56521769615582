import * as Names from './Names';

export default class MachineModel {
  static _augmented(data) {
    const augmented = {};
    if (data) {
      Object.keys(data).forEach(
        (key) => (augmented[key] = { ...data[key], name: key })
      );
    }
    return augmented;
  }

  constructor(machineData) {
    this._states = MachineModel._augmented(machineData[Names.STATES]);
    this._tasks = MachineModel._augmented(machineData[Names.TASKS]);
  }

  _getState(stateName) {
    return this._states[stateName];
  }

  _getTransition(state, eventName) {
    if (state[Names.ON] && state[Names.ON][eventName]) {
      const stateName = state[Names.ON][eventName];
      return this._states[stateName];
    }
    if (state.name !== Names.BASE) {
      const baseState = this._getState(Names.BASE);
      if (baseState) return this._getTransition(baseState, eventName);
    }
  }

  _advanceToSteadyState(state, path) {
    let lastState;
    while (state) {
      path.push(state.name);
      lastState = state;
      state = this._getTransition(state, Names.ADVANCE);
    }
    return lastState;
  }

  getRunState() {
    let state = this._getState(Names.INIT);
    const path = [];
    const runState = this._advanceToSteadyState(state, path);
    return runState.name;
  }

  getStates() {
    let state = this._getState(Names.INIT);
    const path = [];
    const runState = this._advanceToSteadyState(state, path);
    if (runState) {
      const launchState = this._getTransition(runState, Names.LAUNCH);
      if (launchState) {
        this._advanceToSteadyState(launchState, path);
        path.pop();
      }

      const hibernateState = this._getTransition(runState, Names.HIBERNATE);
      if (hibernateState) {
        const frozenState = this._advanceToSteadyState(hibernateState, path);
        if (frozenState) {
          const launchState = this._getTransition(frozenState, Names.LAUNCH);
          if (launchState) {
            this._advanceToSteadyState(launchState, path);
            path.pop();
          }

          const resumeState = this._getTransition(frozenState, Names.RESUME);
          if (resumeState) {
            this._advanceToSteadyState(resumeState, path);
            path.pop();
          }
        }
      }

      const destroyState = this._getTransition(runState, Names.DESTROY);
      if (destroyState) {
        this._advanceToSteadyState(destroyState, path);
      }
    }
    return path;
  }
}
