/**
 * An object that represents a log resource.
 */
export default class LogModel {
  // These fields are defined by the REST API.
  // Declaring them here makes your IDE more helpful.
  cursor; // pagination cursor
  messages; // array of message objects

  /**
   * Constructs a new instance.
   * @param {AbstractClientAdapter} client client adapter that was used to
   *    retrieve `logData`
   * @param {Object} logData API resource representation of the execution
   *    log
   */
  constructor(client, logData) {
    this._client = client;
    Object.assign(this, logData);
  }

  // TODO: provide methods to support backward pagination using the cursor
}
