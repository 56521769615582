import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from 'summit-react';
import Launch from './Launch';
import StackNotFound from './StackNotFound';
import SpinnerCard from '../util/SpinnerCard';

import './Launcher.scss';

export default function Launcher() {
  const { stackId } = useParams();
  const { api } = React.useContext(ApiContext);
  const [stack, setStack] = React.useState(null);
  const [fetched, setFetched] = React.useState(false);

  React.useEffect(() => {
    api.fetchStacks(stackId).then((stacks) => {
      setFetched(true);
      if (stacks.length > 0) {
        setStack(stacks[0]);
      }
    });
  }, []);

  return (
    <div className="launcher container">
      {launcherSubComponent(stackId, fetched, stack)}
    </div>
  );
}

function launcherSubComponent(stackId, fetched, stack) {
  if (!fetched) {
    return <SpinnerCard />;
  }
  if (!stack) {
    return <StackNotFound stackId={stackId} />;
  }

  return <Launch launchStack={stack} />;
}
