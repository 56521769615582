import React from 'react';
import MachineModel from '../../api/MachineModel';
import StackActions from './StackActions';
import Machine from './Machine';
import Spinner from '../util/Spinner';

const REFRESH_DELAY = 5000;

function StackDetail({ stack, onSuspend, onResume }) {
  const [machine, setMachine] = React.useState(new MachineModel({}));
  const [machineReady, setMachineReady] = React.useState(false);
  const [tasks, setTasks] = React.useState([]);
  const [tasksReady, setTasksReady] = React.useState(false);
  const timer = React.useRef(null);

  function refreshTasks() {
    stack.fetchTasks().then((tasks) => {
      // if we don't have a timer, we were suspended after we started the last
      // fetch, so we don't want to update state.
      if (timer.current) {
        setTasks(tasks);
        timer.current = setTimeout(refreshTasks, REFRESH_DELAY);
      }
    });
  }

  function handleSuspend() {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
    onSuspend && onSuspend();
  }

  function handleResume() {
    timer.current = true;
    refreshTasks();
    onResume && onResume();
  }

  React.useEffect(() => {
    stack.fetchMachine().then((machine) => {
      setMachine(machine);
      setMachineReady(true);
    });
    stack.fetchTasks().then((tasks) => {
      setTasks(tasks);
      setTasksReady(true);
      timer.current = setTimeout(refreshTasks, REFRESH_DELAY);
    });
    return () => timer.current && clearTimeout(timer.current);
  }, []);

  return (
    <>
      {machineReady && tasksReady && (
        <>
          <StackActions
            stack={stack}
            onSuspend={handleSuspend}
            onResume={handleResume}
          />
          <Machine
            stack={stack}
            machine={machine}
            tasks={tasks}
            onSuspend={handleSuspend}
            onResume={handleResume}
          />
        </>
      )}
      {!(machineReady && tasksReady) && (
        <div className="spinner">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default StackDetail;
