import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TranslateOptionsFactory, withTranslator } from 'summit-react';

import './Manifest.scss';

function Manifest({ t, stack, onDismiss }) {
  const heading = t(
    'manifest.heading',
    TranslateOptionsFactory.newOptions()
      .returnObjects()
      .interpolateWith({ stackId: stack.stackId })
      .build()
  );

  return (
    <Modal className="manifest" size="md" show={true} onHide={onDismiss}>
      <Modal.Header>
        <span dangerouslySetInnerHTML={{ __html: heading }} />
      </Modal.Header>
      <Modal.Body>
        <pre>{JSON.stringify(stack.manifest, null, 2)}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss}>{t('labels.dismiss')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withTranslator()(Manifest);
