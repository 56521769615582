import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator, TranslateOptionsFactory } from 'summit-react';
import StacksLink from './StacksLink';

function StackMissing({ t, stackId }) {
  const prompt = t(
    'launcher.missing.prompt',
    TranslateOptionsFactory.newOptions()
      .returnObjects()
      .interpolateWith({ stackId })
      .build()
  );

  return (
    <>
      <Card.Body>
        <p dangerouslySetInnerHTML={{ __html: prompt }} />
        <p>{t('launcher.notFound.reason')}</p>
      </Card.Body>
      <Card.Footer>
        <StacksLink />
      </Card.Footer>
    </>
  );
}

export default withTranslator()(StackMissing);
