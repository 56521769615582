import { Button } from 'react-bootstrap';
import React from 'react';

function ChevronControl({ expanded, onClick }) {
  return (
    <Button className="collapse-control" variant="light" onClick={onClick}>
      <ChevronIcon expanded={expanded} />
    </Button>
  );
}

function ChevronIcon({ expanded }) {
  return <i className={chevronClasses(expanded)} />;
}

function chevronClasses(expanded) {
  if (expanded) {
    return 'fas fa-caret-down';
  } else {
    return 'fas fa-caret-right';
  }
}

const Chevron = { Control: ChevronControl, Icon: ChevronIcon };
export default Chevron;
