import * as Names from './Names';
import LogModel from './LogModel';

/**
 * An object that represents a task in a deployed stack.
 */
export default class TaskModel {
  // These fields are defined by the REST API.
  // Declaring them here makes your IDE more helpful.
  href; // resource address for this task
  jobId; // CI job ID
  links; // links used to query and manipulate this task
  pipelineId; // CI pipeline ID
  stackId; // unique ID of the stack
  stackStatus; // stack state name in which this task is defined
  statusTimestamp; // ISO 8601 instant of last change in task status
  taskId; // unique ID of this task (relative to stackStatus)
  taskStatus; // status of this task (e.g. IN_PROGRESS, COMPLETED)

  /**
   * Constructs a new instance
   * @param {AbstractClientAdapter} client the client adapter that was used to
   *    fetch `taskData`
   * @param {Object} taskData the API resource representation of a stack
   */
  constructor(client, taskData) {
    this._client = client;
    Object.assign(this, taskData);
  }

  refresh() {
    return this._client
      .get(this.href)
      .then((task) => new TaskModel(this._client, task))
      .catch(() => null);
  }

  canRetry() {
    // tasks can always be forcibly retried (using the `force` flag below)
    // this method simply discerns the normal case from the forcible case
    return this.taskStatus === 'ERROR' || this.taskStatus === 'FAILED';
  }

  retry(force) {
    let url = this.links[Names.RETRY];
    if (force) {
      url = url + '?force=true';
    }
    return this._client
      .post(url)
      .then((data) => data.message)
      .catch(() => null);
  }

  canFetchLog() {
    return typeof this.links[Names.LOG] !== 'undefined';
  }

  fetchLog() {
    if (!this.canFetchLog()) {
      return Promise.resolve(new LogModel(this._client, {}));
    }
    return this._client
      .get(this.links[Names.LOG])
      .then((data) => new LogModel(this._client, data))
      .catch(() => new LogModel(this._client, {}));
  }
}
