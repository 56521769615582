import React from 'react';
import { Card } from 'react-bootstrap';
import Spinner from './Spinner';

import './SpinnerCard.scss';

export default function SpinnerCard() {
  return (
    <Card className="spinner">
      <Card.Header>
        <Spinner />
      </Card.Header>
    </Card>
  );
}
