import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator, TranslateOptionsFactory } from 'summit-react';
import AwaitingStable from './AwaitingStable';
import ConfirmLaunch from './ConfirmLaunch';
import LaunchComplete from './LaunchComplete';
import StackMissing from './StackMissing';

const REFRESH_DELAY = 2500;

function Launch({ t, launchStack }) {
  const [stack, setStack] = React.useState(launchStack);
  const timer = React.useRef(null);

  function refreshStack() {
    stack.refresh().then((stack) => {
      setStack(stack);
      cancelRefresh();
      if (stack && !stack.isRunning()) {
        scheduleRefresh();
      }
    });
  }

  function scheduleRefresh() {
    if (!timer.current) {
      timer.current = setTimeout(refreshStack, REFRESH_DELAY);
    }
  }

  function cancelRefresh() {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
  }

  function handleConfirm() {
    stack.wake();
    if (!timer.current) {
      scheduleRefresh();
    }
  }

  React.useEffect(() => {
    if (!launchStack.isRunning()) {
      refreshStack();
    }
    return () => {
      cancelRefresh();
    };
  }, []);

  const heading = t(
    'launcher.heading',
    TranslateOptionsFactory.newOptions()
      .returnObjects()
      .interpolateWith({ stackId: launchStack.stackId })
      .build()
  );

  return (
    <Card className="launch">
      <Card.Header>
        <span dangerouslySetInnerHTML={{ __html: heading }} />
      </Card.Header>
      {launchSubComponent(launchStack.stackId, stack, handleConfirm)}
    </Card>
  );
}

function launchSubComponent(stackId, stack, onConfirm) {
  if (!stack) {
    return <StackMissing stackId={stackId} />;
  }
  if (!stack.stable) {
    return <AwaitingStable stack={stack} />;
  }
  if (stack.isRunning()) {
    return <LaunchComplete stack={stack} />;
  }

  return <ConfirmLaunch stack={stack} onConfirm={onConfirm} />;
}

export default withTranslator()(Launch);
