// Names defined by the API
export const ADVANCE = 'advance';
export const BASE = 'BASE';
export const DEPLOYMENT = 'deployment';
export const DESTROY = 'destroy';
export const HIBERNATE = 'hibernate';
export const INIT = 'INIT';
export const LAUNCH = 'launch';
export const LOG = 'log';
export const MACHINE = 'machine';
export const ON = 'on';
export const REDEPLOY = 'redeploy';
export const RESET = 'reset';
export const RESTART = 'restart';
export const RESUME = 'resume';
export const RETRY = 'retry';
export const STACKS = 'stacks';
export const STATES = 'states';
export const TASKS = 'tasks';
