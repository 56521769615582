import React from 'react';

export default function ApplicationLinks({ stack }) {
  function bulletSeparated(links) {
    const components = [];
    for (let i = 0; i < links.length - 1; i++) {
      components.push(links[i]);
      components.push(<span key={`bullet${i}`}>{' \u2022 '}</span>);
    }
    components.push(links[links.length - 1]);
    return components;
  }

  const links = bulletSeparated(
    stack.applications.map((app, index) => (
      <a key={index} href={app.href}>
        {app.text}
      </a>
    ))
  );

  return <>{links}</>;
}
