import React from 'react';
import { withTranslator } from 'summit-react';
import LogModal from '../util/LogModal';
import Chevron from '../util/Chevron';
import ExpansibleTable from '../util/ExpansibleTable';
import DeployedServiceActions from './DeployedServiceActions';
import HealthStatusIcon from './HealthStatusIcon';
import RunStatusIcon from './RunStatusIcon';

import './DeployedServices.scss';

export const SERVICE = 'service';
export const CONTAINER = 'container';

function DeployedServices({ t, stackId, deployment, onSuspend, onResume }) {
  const [showLog, setShowLog] = React.useState(false);
  const [selectedContainer, setSelectedContainer] = React.useState(null);

  function handleShowLog(container) {
    onSuspend && onSuspend();
    setSelectedContainer(container);
    setShowLog(true);
  }

  function handleDismissLog() {
    setShowLog(false);
    onResume && onResume();
  }

  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        className: 'expander',
        id: 'expander',
        Cell: ({ row }) =>
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              <Chevron.Icon expanded={row.isExpanded} />
            </span>
          ) : null,
      },
      {
        Header: t('deployment.services.labels.name'),
        className: 'name',
        accessor: 'name',
        Cell: ({ row }) => (
          <span className={`stack-${row.original.type}`}>
            {row.values.name}
          </span>
        ),
      },
      {
        Header: t('deployment.services.labels.timestamp'),
        className: 'timestamp',
        accessor: 'timestamp',
      },
      {
        Header: t('deployment.services.labels.status'),
        className: 'status',
        accessor: 'status',
        Cell: ({ row }) => (
          <>
            <RunStatusIcon status={row.values.status} /> {row.values.status}
          </>
        ),
      },
      {
        Header: t('deployment.services.labels.health'),
        className: 'health',
        accessor: 'health',
        Cell: ({ row }) => (
          <>
            <HealthStatusIcon status={row.values.health} /> {row.values.health}
          </>
        ),
      },
      {
        Header: t('deployment.services.labels.actions'),
        className: 'actions',
        Cell: ({ row }) => (
          <DeployedServiceActions
            stackId={stackId}
            row={row}
            onShowLog={handleShowLog}
            onSuspend={onSuspend}
            onResume={onResume}
          />
        ),
      },
    ],
    [deployment]
  );

  const services = React.useMemo(
    () => makeServiceData(deployment),
    [deployment]
  );

  return (
    <div className="services">
      <ExpansibleTable
        columns={columns}
        data={services}
        expandClassName="stack-container"
      />
      {showLog && (
        <LogModal
          title={t('deployment.logTitle')}
          loggable={selectedContainer}
          show={showLog}
          onHide={handleDismissLog}
        />
      )}
    </div>
  );
}

function makeServiceData(deployment) {
  if (!deployment) return [];
  const serviceNames = [...Object.keys(deployment.services)];
  serviceNames.sort();
  return serviceNames.map((name) => {
    const service = deployment.services[name];
    return {
      type: SERVICE,
      name: name,
      timestamp: timestamp(service.startedAt),
      status: service.status.reported,
      health: service.status.health,
      model: service,
      subRows: makeContainerData(service.containers),
    };
  });
}

function makeContainerData(containers) {
  const sortedContainers = [...containers].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return sortedContainers.map((container) => ({
    type: CONTAINER,
    name: container.name,
    timestamp: '',
    status: container.status.reported,
    health: container.status.health,
    model: container,
  }));
}

function timestamp(isoInstant) {
  if (!isoInstant) return '(not running)';
  return new Date(isoInstant).toLocaleString();
}

export default withTranslator()(DeployedServices);
