import React from 'react';
import { Button } from 'react-bootstrap';

function TaskActions({ task, onShowLog, onAction }) {
  return (
    <div className="task-actions">
      {task.canFetchLog() && (
        <Button variant="light" onClick={() => onShowLog(task)}>
          <i className="far fa-file-alt" />
        </Button>
      )}
      {task.canRetry() && (
        <Button
          variant="light"
          onClick={() => onAction(task, 'retry', () => task.retry(false))}
        >
          <i className="fas fa-redo-alt" />
        </Button>
      )}
      {!task.canRetry() && (
        <Button
          variant="light"
          onClick={() =>
            onAction(task, 'forciblyRetry', () => task.retry(true))
          }
        >
          <i className="fas fa-redo-alt" />
        </Button>
      )}
    </div>
  );
}

export default TaskActions;
