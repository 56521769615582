import React from 'react';
import { withTranslator } from 'summit-react';
import Spinner from '../util/Spinner';
import DeployedServices from './DeployedServices';

import './Deployment.scss';

function Deployment({ t, stack, onSuspend, onResume, onHealthChange }) {
  const [deployment, setDeployment] = React.useState(null);
  const [fetched, setFetched] = React.useState(false);

  React.useEffect(() => {
    stack.isDeployed() &&
      stack.fetchDeployment().then((deployment) => {
        setFetched(true);
        setDeployment(deployment);
      });
  }, [stack]);

  React.useEffect(() => {
    if (deployment) {
      onHealthChange && onHealthChange(deployment.health());
    }
  }, [deployment]);
  return (
    <div className="deployment">
      {deploymentComponent(t, stack, fetched, deployment, onSuspend, onResume)}
    </div>
  );
}

function deploymentComponent(
  t,
  stack,
  fetched,
  deployment,
  onSuspend,
  onResume
) {
  if (!stack.isDeployed()) {
    return <p>{t('deployment.notDeployed')}</p>;
  } else if (!fetched) {
    return (
      <div className="spinner">
        <Spinner />
      </div>
    );
  } else {
    return (
      <>
        <DeployedServices
          stackId={stack.stackId}
          deployment={deployment}
          onSuspend={onSuspend}
          onResume={onResume}
        />
      </>
    );
  }
}

export default withTranslator()(Deployment);
