import * as Names from './Names';
import StackModel from './StackModel';

export default class API {
  /**
   * Constructs a new instance
   * @param {AbstractClientAdapter} client adapter that will be used to access
   *    the API
   */
  constructor(client) {
    this._client = client;
  }

  /**
   * Maps an array of stack resources from the API to an array of StackModel
   * objects, and optionally filters to a match a particular stack.
   *
   * Since the API doesn't provide a way to filter the `stacks` resource to
   * a single stack, we do that here.
   *
   * @param {Object[]} stacks array of API stack resources
   * @param {string} stackId optional ID of a stack to match
   * @returns array of StackModel objects
   * @private
   */
  _mapAndFilterStacks(stacks, stackId) {
    return stacks
      .map((stack) => new StackModel(this._client, stack))
      .filter((stack) => !stackId || (stackId && stack.stackId === stackId));
  }

  /**
   * Fetches the `stacks` resource.
   * @param {string} [stackId] ID of a stack to match in the response
   * @returns {Promise} a promise that resolves to an array of StackModel
   *    objects
   */
  fetchStacks(stackId) {
    if (this._root) {
      // if we've already fetched the root resource, reuse it to fetch
      // the `stacks` resource
      return this._client
        .get(this._root[Names.STACKS])
        .then((stacks) => this._mapAndFilterStacks(stacks, stackId))
        .catch(() => []);
    } else {
      // fetch and cache the root resource, then use it to retrieve the
      // `stacks` resource
      return this._client
        .get('/')
        .then((root) => {
          this._root = root;
          return this._client.get(root[Names.STACKS]);
        })
        .then((stacks) => this._mapAndFilterStacks(stacks, stackId))
        .catch(() => []);
    }
  }
}
