import React from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import Chevron from '../util/Chevron';
import LocaleDateTime from '../util/LocaleDateTime';
import ApplicationLinks from './ApplicationLinks';
import StackDetail from './StackDetail';
import StackStatus from './StackStatus';

import './Stack.scss';

function Stack({ t, stack, onSuspend, onResume }) {
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandCollapse() {
    setExpanded(!expanded);
  }

  return (
    <Card className="stack">
      <Card.Header className="stack-header">
        <div className="row">
          <div className="col">
            <Chevron.Control
              expanded={expanded}
              onClick={handleExpandCollapse}
            />
            {stack.stackId}
          </div>
          <div className="col-6">
            <ApplicationLinks stack={stack} />
          </div>
          <div className="col">
            <LocaleDateTime isoInstant={stack.lastModified} />
          </div>
          <div className="col">
            <StackStatus stack={stack} />
          </div>
        </div>
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Body>
          {expanded && (
            <StackDetail
              stack={stack}
              onSuspend={onSuspend}
              onResume={onResume}
            />
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
}

export default withTranslator()(Stack);
