import React from 'react';
import './MainRouter.scss';
import Switch from 'react-bootstrap/Switch';
import { Route, Routes } from 'react-router-dom';
import Stacks from '../Stacks/Stacks';
import Launcher from '../Launcher/Launcher';

export const STACKS_PATH = '/';
export const LAUNCH_PATH = '/launch/:stackId';

function MainRouter() {
  return (
    <div className="main-router">
      <Switch>
        <Routes>
          <Route path={STACKS_PATH} element={<Stacks />} exact />
          <Route path={LAUNCH_PATH} element={<Launcher />} />
        </Routes>
      </Switch>
    </div>
  );
}

export default MainRouter;
