import React from 'react';
import { Button } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import ConfirmAction from './ConfirmAction';
import Manifest from './Manifest';

function StackActions({ t, stack, onSuspend, onResume }) {
  const [action, setAction] = React.useState(null);
  const [variant, setVariant] = React.useState(null);
  const [actionKey, setActionKey] = React.useState(null);
  const [showManifest, setShowManifest] = React.useState(false);

  function handleConfirmOutcome(confirmed) {
    if (confirmed) {
      action();
    }
    setAction(null);
    setVariant(null);
    setActionKey(null);
    onResume && onResume();
  }

  function confirmAction(name, variant, action) {
    onSuspend && onSuspend();
    setAction(() => action);
    setVariant(variant);
    setActionKey(`stack.actions.${name}`);
  }

  return (
    <div className="stack-actions">
      <div className="row">
        <div className="non-destructive col">
          <Button onClick={() => setShowManifest(true)}>View Manifest</Button>
          {stack.canWake() && (
            <Button
              onClick={() =>
                confirmAction('wake', 'primary', () => stack.wake())
              }
            >
              <i className="fas fa-sun" /> {t('stack.labels.wake')}
            </Button>
          )}
          {stack.canSleep() && (
            <Button
              onClick={() =>
                confirmAction('sleep', 'primary', () => stack.sleep())
              }
            >
              <i className="fas fa-moon" /> {t('stack.labels.sleep')}
            </Button>
          )}
        </div>
        <div className="destructive col">
          {stack.canReset() && (
            <Button
              className="reset"
              onClick={() =>
                confirmAction('reset', 'warning', () => stack.reset())
              }
            >
              <i className="fas fa-database" /> {t('stack.labels.reset')}
            </Button>
          )}
          {stack.canRedeploy() && (
            <Button
              className="re-deploy"
              onClick={() =>
                confirmAction('redeploy', 'warning', () => stack.redeploy())
              }
            >
              <i className="fas fa-recycle" /> {t('stack.labels.redeploy')}
            </Button>
          )}
          {stack.canDestroy() && (
            <Button
              className="destroy"
              onClick={() =>
                confirmAction('destroy', 'danger', () => stack.destroy())
              }
            >
              <i className="fas fa-trash" /> {t('stack.labels.destroy')}
            </Button>
          )}
        </div>
      </div>
      {action !== null && (
        <ConfirmAction
          actionKey={actionKey}
          confirmVariant={variant}
          onOutcome={handleConfirmOutcome}
          translateContext={{ stackId: stack.stackId }}
        />
      )}
      {showManifest && (
        <Manifest stack={stack} onDismiss={() => setShowManifest(false)} />
      )}
    </div>
  );
}

export default withTranslator()(StackActions);
