/**
 * An object that represents the deployed resources for a stack.
 */
import StackServiceModel from './StackServiceModel';
import RunStatus from './RunStatus';
import HealthStatus from './HealthStatus';

export default class DeploymentModel {
  // These fields are defined by the REST API.
  // Declaring them here makes your IDE more helpful.
  href; // resource address for this deployment
  status; // overall deployment status
  services; // service units

  /**
   * Constructs a new instance
   * @param {AbstractClientAdapter} client the client adapter that was used to
   *    fetch `deploymentData`
   * @param {Object} deploymentData the API resource representation of the
   *    deployment
   */
  constructor(client, deploymentData) {
    this._client = client;
    Object.assign(this, deploymentData);
    Object.keys(this.services).forEach((key) => {
      this.services[key] = new StackServiceModel(
        client,
        key,
        this.services[key]
      );
    });
  }

  /**
   * Refreshes this deployment.
   * @returns {Promise} a promise that resolves to a new DeploymentModel instance
   *    whose state represents the current API representation of the deployment
   */
  refresh() {
    return this._client
      .get(this.href)
      .then((deployment) => new DeploymentModel(this._client, deployment))
      .catch(() => null);
  }

  /**
   * Gets the overall run status of the deployment as a function of the
   * desired run status and reported status of each service.
   * @returns {string|null}
   */
  runStatus() {
    if (this.status.desired !== RunStatus.RUNNING) {
      return this.status.reported;
    } else {
      return Object.keys(this.services)
        .map((name) => this.services[name])
        .map((service) => service.status.reported)
        .reduce((prev, cur) => {
          if (prev === RunStatus.STOPPED || prev === RunStatus.PENDING)
            return prev;
          if (cur !== RunStatus.STOPPED && cur !== RunStatus.RUNNING) {
            return RunStatus.PENDING;
          }
          return cur;
        });
    }
  }

  /**
   * Gets the overall health status of the deployment as a function of the
   * desired run status and health status of each service
   * @returns {string|null}
   */
  health() {
    if (this.status.desired !== RunStatus.RUNNING) {
      return null;
    } else if (this.status.health === HealthStatus.HEALTHY) {
      return HealthStatus.HEALTHY;
    } else {
      const status = Object.keys(this.services)
        .map((name) => this.services[name])
        .map((service) => service.status)
        .reduce((prev, cur) => {
          if (
            prev &&
            (prev.health === HealthStatus.UNHEALTHY ||
              prev.health === HealthStatus.PENDING)
          ) {
            return prev;
          }
          if (cur.reported === RunStatus.STOPPED) {
            return { ...cur, health: HealthStatus.UNHEALTHY };
          }
          if (cur.reported !== RunStatus.RUNNING) {
            return { ...cur, health: HealthStatus.PENDING };
          }
          return this.status;
        });
      return status.health;
    }
  }
}
