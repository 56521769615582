import React from 'react';
import { ApiContext } from 'summit-react';
import { withTranslator } from 'summit-react';
import SpinnerCard from '../util/SpinnerCard';
import StackCards from './StackCards';
import StacksEmpty from './StacksEmpty';

import './Stacks.scss';
import { Container } from 'react-bootstrap';

const REFRESH_DELAY = 5000;

function Stacks({ t }) {
  const [stacks, setStacks] = React.useState([]);
  const [stacksReady, setStacksReady] = React.useState(false);
  const { api } = React.useContext(ApiContext);
  const timer = React.useRef(null);

  function refreshStacks() {
    api.fetchStacks().then((stacks) => {
      // if we don't have a timer, we were suspended after we started the last
      // fetch, so we don't want to update state.
      if (timer.current) {
        setStacks(stacks);
        timer.current = setTimeout(refreshStacks, REFRESH_DELAY);
      }
    });
  }

  function handleSuspend() {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
  }

  function handleResume() {
    timer.current = true;
    refreshStacks();
  }

  React.useEffect(() => {
    api.fetchStacks().then((stacks) => {
      setStacks(stacks);
      setStacksReady(true);
      timer.current = setTimeout(refreshStacks, REFRESH_DELAY);
    });
    return () => timer.current && clearTimeout(timer.current);
  }, [api]);

  return (
    <Container fluid={'xl'} className="stacks">
      {stacksReady && stacks.length > 0 && (
        <StackCards
          stacks={stacks}
          onSuspend={handleSuspend}
          onResume={handleResume}
        />
      )}
      {stacksReady && stacks.length === 0 && <StacksEmpty />}
      {!stacksReady && <SpinnerCard />}
    </Container>
  );
}

export default withTranslator()(Stacks);
