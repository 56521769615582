import React from 'react';
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from 'react-bootstrap';
import Chevron from '../util/Chevron';
import Tasks from './Tasks';
import Deployment from './Deployment';

import './Machine.scss';
import TaskStatusIcon from './TaskStatusIcon';
import HealthStatusIcon from './HealthStatusIcon';

export default function Machine({
  stack,
  machine,
  tasks,
  onSuspend,
  onResume,
}) {
  const [stateTasks, setStateTasks] = React.useState({});
  const [activeMachineState, setActiveMachineState] = React.useState('');
  const [selectedMachineState, setSelectedMachineState] = React.useState('');

  const runStatus = machine.getRunState();

  React.useEffect(() => {
    if (tasks) {
      const stateTasks = {};
      machine.getStates().forEach((status) => {
        stateTasks[status] = tasks.filter(
          (task) => task.stackStatus === status
        );
      });
      let activeState = '';
      if (
        stack.stackStatus === runStatus ||
        (stateTasks[stack.stackStatus] &&
          stateTasks[stack.stackStatus].length > 0)
      ) {
        activeState = stack.stackStatus;
      } else if (stack.stackPriorStatus) {
        activeState = stack.stackPriorStatus;
      }
      setStateTasks(stateTasks);
      setActiveMachineState(activeState);
    }
  }, [stack, machine, tasks]);

  function handleStateSelection(status) {
    setSelectedMachineState(status !== activeMachineState ? status : '');
  }

  const machineStates = [];
  machine &&
    machine.getStates().forEach((status) => {
      if (status === runStatus) {
        machineStates.push(
          <RunState
            key={status}
            status={status}
            stack={stack}
            expanded={status === activeMachineState}
            onExpand={handleStateSelection}
            onSuspend={onSuspend}
            onResume={onResume}
          />
        );
      } else if (stateTasks[status] && stateTasks[status].length > 0) {
        machineStates.push(
          <MachineState
            key={status}
            status={status}
            tasks={stateTasks[status]}
            expanded={status === activeMachineState}
            onExpand={handleStateSelection}
            onSuspend={onSuspend}
            onResume={onResume}
          />
        );
      }
    });

  const activeKey = selectedMachineState
    ? selectedMachineState
    : activeMachineState;

  return (
    <Accordion className="machine" activeKey={activeKey}>
      {machineStates}
    </Accordion>
  );
}

function RunState({ status, stack, expanded, onExpand, onSuspend, onResume }) {
  const [health, setHealth] = React.useState(null);

  const renderStatusIcon = React.useCallback(
    () => <HealthStatusIcon status={health} />,
    [health]
  );

  return (
    <Card className={expanded ? 'expanded' : ''}>
      <AccordionToggle
        eventKey={status}
        onExpand={onExpand}
        renderIndicatorComponent={renderStatusIcon}
      />
      <Accordion.Collapse eventKey={status}>
        <Deployment
          stack={stack}
          onSuspend={onSuspend}
          onResume={onResume}
          onHealthChange={setHealth}
        />
      </Accordion.Collapse>
    </Card>
  );
}

function MachineState({
  status,
  tasks,
  expanded,
  onExpand,
  onSuspend,
  onResume,
}) {
  const renderStatusIcon = React.useCallback(() => {
    const status = tasks
      .map((task) => task.taskStatus)
      .reduce((prev, cur) => {
        if (prev && prev !== 'COMPLETED') return prev;
        return cur;
      });
    return <TaskStatusIcon taskStatus={status !== 'COMPLETED' && status} />;
  }, [tasks]);

  return (
    <Card className={expanded ? 'expanded' : ''}>
      <AccordionToggle
        eventKey={status}
        onExpand={onExpand}
        renderIndicatorComponent={renderStatusIcon}
      />
      <Accordion.Collapse eventKey={status}>
        <MachineTasks tasks={tasks} onSuspend={onSuspend} onResume={onResume} />
      </Accordion.Collapse>
    </Card>
  );
}

function MachineTasks({ tasks, onSuspend, onResume }) {
  return (
    <Card.Body>
      <Tasks tasks={tasks} onSuspend={onSuspend} onResume={onResume} />
    </Card.Body>
  );
}

function AccordionToggle({ eventKey, onExpand, renderIndicatorComponent }) {
  const accordionEventKey = React.useContext(AccordionContext);
  const onClickDecorator = useAccordionToggle(
    eventKey,
    () => onExpand && !expanded && onExpand(eventKey)
  );

  const expanded = accordionEventKey === eventKey;

  return (
    <Card.Header
      className={expanded ? 'expanded' : ''}
      onClick={onClickDecorator}
    >
      <div className="row">
        <div className="col">
          <Chevron.Control expanded={expanded} />
          {eventKey}
        </div>
        <div className="indicator col">
          {renderIndicatorComponent && renderIndicatorComponent()}
        </div>
      </div>
    </Card.Header>
  );
}
