import * as Names from './Names';
import DeploymentModel from './DeploymentModel';
import MachineModel from './MachineModel';
import TaskModel from './TaskModel';

/**
 * An object that represents a stack.
 */
export default class StackModel {
  // These fields are defined by the REST API.
  // Declaring them here makes your IDE more helpful.
  applications; // array of application links
  href; // resource address for this stack
  jobId; // CI job ID
  lastModified; // ISO 8601 instant at which stack was last modified
  links; // links used to query and manipulate this stack
  manifest; // CI build manifest
  pipelineId; // CI pipeline ID
  stable; // flag indicating whether the stack state is stable
  stackId; // unique ID of the stack
  stackStatus; // status of the stack (e.g. INIT, RUN, etc)
  stackPriorStatus; // prior status of the stack
  statusTimestamp; // ISO 8601 instant at which status was last changed
  taskStatus; // overall task status (e.g. PENDING, COMPLETED, etc)

  /**
   * Constructs a new instance
   * @param {AbstractClientAdapter} client the client adapter that was used to
   *    fetch `stackData`
   * @param {Object} stackData the API resource representation of a stack
   */
  constructor(client, stackData) {
    this._client = client;
    Object.assign(this, stackData);
  }

  /**
   * Refreshes this stack.
   * @returns {Promise} a promise that resolves to a new StackModel instance
   *    whose state represents the current API representation of the stack
   */
  refresh() {
    return this._client
      .get(this.href)
      .then((stack) => new StackModel(this._client, stack))
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this stack has a deployment.
   * @returns {boolean} flag state
   */
  isDeployed() {
    return typeof this.links[Names.DEPLOYMENT] !== 'undefined';
  }

  /**
   * Fetches the deployment for the stack.
   * @returns {Promise} a promise that resolves to a DeploymentModel
   *    instance for the stack's deployment
   */
  fetchDeployment() {
    return this._client
      .get(this.links[Names.DEPLOYMENT])
      .then((deployment) => new DeploymentModel(this._client, deployment))
      .catch(() => null);
  }

  /**
   * Fetches the state machine for this stack.
   * @returns {Promise} a promise that resolves to a MachineModel instance for
   *    the stack's state machine
   */
  fetchMachine() {
    return this._client
      .get(this.links[Names.MACHINE])
      .then((machine) => new MachineModel(machine))
      .catch((err) => null);
  }

  /**
   * Fetches the tasks for this stack.
   * @returns {Promise} a promise that resolves an array of TaskModel instances
   *    associated with the stack
   */
  fetchTasks() {
    return this._client
      .get(this.links[Names.TASKS])
      .then((tasks) => tasks.map((task) => new TaskModel(this._client, task)))
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this stack is in a running state.
   * @returns {boolean} flag state
   */
  isRunning() {
    return this.canSleep();
  }

  /**
   * Gets a flag indicating whether this stack can be awakened.
   * @returns {boolean} flag state
   */
  canWake() {
    return typeof this.links[Names.RESUME] !== 'undefined';
  }

  /**
   * Invokes this stack's `resume` link to awaken it.
   * @returns {Promise} a promise that resolves to the message in the API
   *    response to the `resume` request
   */
  wake() {
    return this._client
      .post(this.links[Names.RESUME])
      .then((data) => data.message)
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this stack can be put to sleep.
   * @returns {boolean} flag state
   */
  canSleep() {
    return typeof this.links[Names.HIBERNATE] !== 'undefined';
  }

  /**
   * Invokes this stack's `hibernate` link to awaken it.
   * @returns {Promise} a promise that resolves to the message in the API
   *    response to the `hibernate` request
   */
  sleep() {
    return this._client
      .post(this.links[Names.HIBERNATE])
      .then((data) => data.message)
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this stack can be destroyed.
   * @returns {boolean} flag state
   */
  canDestroy() {
    return typeof this.links[Names.DESTROY] !== 'undefined';
  }

  /**
   * Invokes this stack's `destroy` link to destroy it.
   * @returns {Promise} a promise that resolves to the message in the API
   *    response to the `destroy` request
   */
  destroy() {
    return this._client
      .post(this.links[Names.DESTROY])
      .then((data) => data.message)
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this stack can be re-deployed.
   * @returns {boolean} flag state
   */
  canRedeploy() {
    return typeof this.links[Names.REDEPLOY] !== 'undefined';
  }

  /**
   * Invokes this stack's `redeploy` link to destroy and re-launch it.
   * @returns {Promise} a promise that resolves to the message in the API
   *    response to the `redeploy` request
   */
  redeploy() {
    return this._client
      .post(this.links[Names.REDEPLOY])
      .then((data) => data.message)
      .catch(() => null);
  }

  /**
   * Gets a flag indicating whether this stack can be reset.
   * @returns {boolean} flag state
   */
  canReset() {
    return typeof this.links[Names.RESET] !== 'undefined';
  }

  /**
   * Invokes this stack's `reset` link to reset it.
   * @returns {Promise} a promise that resolves to the message in the API
   *    response to the `reset` request
   */
  reset() {
    return this._client
      .post(this.links[Names.RESET])
      .then((data) => data.message)
      .catch(() => null);
  }
}
