import React from 'react';
import TaskStatusIcon from './TaskStatusIcon';

export default function StackStatus({ stack }) {
  return (
    <>
      <TaskStatusIcon taskStatus={stack.taskStatus} forStack={true} />
      {stack.stackStatus}
    </>
  );
}
