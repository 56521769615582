import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withTranslator } from 'summit-react';
import AnimatedEllipsis from './AnimatedEllipsis';
import Chevron from './Chevron';
import ExpansibleTable from './ExpansibleTable';
import LocaleDateTime from './LocaleDateTime';
import Spinner from './Spinner';

import './LogModal.scss';

const REFRESH_DELAY = 5000;

function LogModal({ t, title, loggable, show, onHide }) {
  const [log, setLog] = React.useState([]);
  const [fetched, setFetched] = React.useState(false);
  const [autoRefresh, setAutoRefresh] = React.useState(false);
  const timer = React.useRef(null);

  function setTimer() {
    timer.current = setTimeout(refresh, REFRESH_DELAY);
  }

  function cancelTimer() {
    timer.current && clearTimeout(timer.current);
  }

  function refresh() {
    loggable &&
      loggable.fetchLog().then((log) => {
        setLog(log);
        setTimer();
      });
  }

  React.useEffect(() => {
    loggable &&
      loggable.fetchLog().then((log) => {
        setLog(log);
        setFetched(true);
      });
    return () => cancelTimer();
  }, [loggable]);

  function handleRefreshControl(event) {
    setAutoRefresh(event.target.checked);
    if (event.target.checked) {
      refresh();
    } else {
      cancelTimer();
    }
  }

  return (
    <Modal className="log-modal" size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <h4>{title}</h4>
        </div>
      </Modal.Header>
      <Modal.Body>{modalBodyComponent(t, fetched, log)}</Modal.Body>
      <Modal.Footer>
        {fetched && (
          <div className="row">
            <div className="checkbox col">
              <input
                checked={autoRefresh}
                type="checkbox"
                onChange={handleRefreshControl}
              />
              {t('labels.autoRefresh')}
            </div>
            {autoRefresh && (
              <div className="ellipsis col">
                <AnimatedEllipsis fontSize="24pt" />
              </div>
            )}
            <div className="button col">
              <Button onClick={onHide}>{t('labels.dismiss')}</Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function modalBodyComponent(t, fetched, log) {
  if (!fetched) {
    return (
      <div className="spinner">
        <Spinner />
      </div>
    );
  }
  if (!log || !log.messages || !log.messages.length) {
    return <p className="no-messages">{t('logModal.noMessages')}</p>;
  }
  return <LogTable t={t} messages={log.messages}></LogTable>;
}

function LogTable({ t, messages }) {
  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        className: 'expander',
        id: 'expander',
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            <Chevron.Icon expanded={row.isExpanded} />
          </span>
        ),
      },
      {
        Header: t('logModal.labels.timestamp'),
        accessor: 'timestamp',
        className: 'timestamp',
        Cell: ({ row }) => <LocaleDateTime isoInstant={row.values.timestamp} />,
      },
      {
        Header: t('logModal.labels.message'),
        accessor: 'message',
        className: 'message',
      },
    ],
    []
  );

  const renderFullMessageText = React.useCallback(
    ({ row }) => <p>{row.values.message}</p>,
    []
  );

  return (
    <div>
      <ExpansibleTable
        columns={columns}
        data={messages}
        expandClassName="full-message"
        renderExpandComponent={renderFullMessageText}
      />
    </div>
  );
}

export default withTranslator()(LogModal);
