import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslator, TranslateOptionsFactory } from 'summit-react';
import StacksLink from './StacksLink';

function StackNotFound({ t, stackId }) {
  const { heading, prompt, reason } = t(
    'launcher.notFound',
    TranslateOptionsFactory.newOptions()
      .returnObjects()
      .interpolateWith({ stackId })
      .build()
  );

  return (
    <Card className="launch not-found">
      <Card.Header>
        <span dangerouslySetInnerHTML={{ __html: heading }} />
      </Card.Header>
      <Card.Body>
        <p dangerouslySetInnerHTML={{ __html: prompt }} />
        <p dangerouslySetInnerHTML={{ __html: reason }} />
      </Card.Body>
      <Card.Footer>
        <StacksLink />
      </Card.Footer>
    </Card>
  );
}

export default withTranslator()(StackNotFound);
