import React from 'react';
import { TranslateOptionsFactory, withTranslator } from 'summit-react';

import ConfirmDialog from '../util/ConfirmDialog';

function ConfirmAction({
  t,
  show = true,
  actionKey,
  translateContext,
  confirmVariant,
  onOutcome,
}) {
  const { title, prompt } =
    show &&
    t(
      actionKey,
      TranslateOptionsFactory.newOptions()
        .returnObjects()
        .interpolateWith(translateContext)
        .build()
    );

  return (
    <ConfirmDialog
      show={show}
      title={<span dangerouslySetInnerHTML={{ __html: title }} />}
      confirmVariant={confirmVariant}
      onOutcome={onOutcome}
    >
      <span dangerouslySetInnerHTML={{ __html: prompt }} />
    </ConfirmDialog>
  );
}

export default withTranslator()(ConfirmAction);
