import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { TranslateOptionsFactory, withTranslator } from 'summit-react';
import StacksLink from './StacksLink';

function ConfirmLaunch({ t, stack, onConfirm }) {
  const [confirmed, setConfirmed] = React.useState(false);

  function launchStack() {
    setConfirmed(true);
    onConfirm && onConfirm();
  }

  const detail = t(
    'launcher.confirm.detail',
    TranslateOptionsFactory.newOptions()
      .returnObjects()
      .interpolateWith({ stack })
      .build()
  );

  return (
    <>
      <Card.Body>
        <p dangerouslySetInnerHTML={{ __html: detail }} />
        <p>{t('launcher.confirm.prompt')}</p>
      </Card.Body>
      <Card.Footer className="footer-buttons">
        <div className="row">
          <div className="stacks-link col">
            <StacksLink />
          </div>
          <div className="col">
            <Button disabled={confirmed} onClick={launchStack}>
              {t('launcher.confirm.label')}
            </Button>
          </div>
        </div>
      </Card.Footer>
    </>
  );
}

export default withTranslator()(ConfirmLaunch);
