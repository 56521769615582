/**
 * An object that represents a service unit in the deployment for a stack.
 */
import StackContainerModel from './StackContainerModel';
import * as Names from './Names';

export default class StackServiceModel {
  // These fields are defined by the REST API.
  // Declaring them here makes your IDE more helpful.
  name; // name of the service
  status; // status of the service
  startedAt; // ISO 8601 instant at which the service was started by ECS
  links; // links that can be used to manipulate the service
  containers; // array of containers

  /**
   * Constructs a new instance.
   * @param {AbstractClientAdapter} client the client adapter that was used to
   *    fetch `containerData`
   * @param {string} serviceName the name of the service
   * @param {Object} serviceData the API representation of a container in
   *    a stack deployment
   */
  constructor(client, serviceName, serviceData) {
    this._client = client;
    Object.assign(this, serviceData);
    this.name = serviceName;
    this.containers = this.containers.map(
      (containerData) => new StackContainerModel(client, containerData)
    );
  }

  /**
   * Gets a flag indicating whether this service can be restarted.
   * @returns {boolean} flag state
   */
  canRestart() {
    return this.links && typeof this.links[Names.RESTART] !== 'undefined';
  }

  /**
   * Sends a request to restart this service.
   * @returns {Promise} whatever data is returned from the restart request
   */
  restart() {
    return (
      this.links[Names.RESTART] &&
      this._client
        .post(this.links[Names.RESTART])
        .then((data) => data)
        .catch(() => null)
    );
  }
}
