import React from 'react';

import './StatusIcon.scss';

export default function TaskStatusIcon({
  taskStatus: status,
  forStack = false,
}) {
  return (
    <span className="status-icon">
      <i className={iconClasses(status, forStack)} />
    </span>
  );
}

function iconClasses(taskStatus, forStack) {
  if (forStack && taskStatus === 'PENDING') {
    taskStatus = 'IN_PROGRESS';
  }
  switch (taskStatus) {
    case 'PENDING':
      return 'fas fa-question-circle';
    case 'IN_PROGRESS':
      return 'fas fa-spin fa-circle-notch';
    case 'COMPLETED':
      return 'green fas fa-check-circle';
    case 'ERROR':
    case 'FAILED':
      return 'red fas fa-times-circle';
    default:
      return '';
  }
}
