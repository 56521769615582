import React from 'react';
import HealthStatus from '../../api/HealthStatus';

import './StatusIcon.scss';

export default function HealthStatusIcon({ status }) {
  return (
    <span className="status-icon">
      <i className={iconClasses(status)} />
    </span>
  );
}

function iconClasses(status) {
  switch (status) {
    case HealthStatus.PENDING:
      return 'fas fa-spin fa-circle-notch';
    case HealthStatus.UNKNOWN:
      return 'blue fas fa-question-circle';
    case HealthStatus.UNHEALTHY:
      return 'red fas fa-times-circle';
    case HealthStatus.HEALTHY:
      return 'green fas fa-check-circle';
    default:
      return '';
  }
}
